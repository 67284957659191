import React, { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled, { css } from 'styled-components';
import InstaShowcase from "./InstaShowcase"
import InstaShowcase2 from "./Showcase2"

import icon from '../../assets/images/Icon.png'
import copy from '../../assets/images/fi-br-copy.png'
import qr from '../../assets/images/qr-code.png'
import whitepaper from '../../assets/images/whitepaper.png'
import audit from '../../assets/images/audit-01.png'
import kyc from '../../assets/images/kyc-1.png'
import Tokenomics from './Tokenomics'
import Leone from "../../assets/images/Leone.png"
import LeoneNew from "../../assets/images/leoneNew.png"


const MainWrapper = styled.div`
margin: 50px 0px;
max-width:100%;
border-radius:20px;
padding:20px;
grid-template-columns:20% 80%;


p{
  text-align:center;
  font-size:17px;
  color:#ccc;
  margin:0 30%;

}

h1{
  text-align:center;
  color:#fff;
  font-weight:500;
  font-size:45px;
  font-family: var(--font-bold);
  @media (max-width: 768px) {
    font-size:22px;
   
   }
}


`;


const AuditSheild = styled.div`
padding:0px 80px;
margin-top:30px;

@media (max-width: 768px) {
  padding:0px 0px;

}

.token-alloc{
  display:grid;
  @media (max-width: 768px) {
    grid-template-columns:1fr ;

  }
}

.alloc-num{
  display:grid;
  margin-top:40px;
  gap:20px;
  column-gap:30px;
  grid-template-columns:1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns:1fr ;

  }
}
`;

const AuditSheilds = styled.div`
background-color:#101010;
padding:30px;
background-repeat:no-repeat;
border-radius:20px;
background-size: contain;

display:grid;
grid-template-columns:55% auto ;
background-position:right;


@media (max-width: 768px) {
  grid-template-columns:1fr ;
  background-image:none;
gap:20px;


}


@media (max-width: 768px) {
p{
  font-size:15px
}

}
.imggg{
  width:500px;

  @media (max-width: 768px) {
    width:250px;

  
  }
}
.buttons{
  display:grid;
  margin-top:40px;
  gap:10px;
  grid-template-columns:1fr 1fr 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns:1fr ;

  }
}

button div{
  display:flex;
  justify-content:space-between;
align-items:center;
}

button{
  background:black;
  color:white;
  border:none;
  border-radius:10px;
  padding:15px 20px;
  font-size:20px;
}
`;
const Audits = styled.div`
display:grid;
margin-top:0px;
background-color:#101010;
width:100%;
grid-template-columns:1fr 1fr 1fr;
gap:40px;
@media (max-width: 768px) {
  grid-template-columns:1fr;
  gap:20px;

}

.auditkyc{
  background-color:#262626;
  border-radius:15px;
  padding:15px 25px;
}


img{
  margin-bottom:20px;
  width:160px;
  height:160px;
}

`;



const AuditDetails = styled.div`
padding:30px;
width:100%;
margin-top:20px;
border-radius:20px;
background-color:black;

p{
  font-size:14px;
}

`;


const Icon = styled.div`
display:flex;
justify-content:center;
align-items:center;
gap:20px;
margin-top:20px;

svg{
  width:50px;
  height:50px;
}

img{
  width:50px;
  height:50px;
}


@media (max-width: 768px) {
  display:grid;
  grid-template-columns:1fr 1fr 1fr;
  gap:10px;
  svg{
    width:40px;
    height:40px;
  }
  
  img{
    width:40px;
    height:40px;
  }
}
`;


const BlockThree: React.FC = () => {
  


  return (
    <div className="blockthree-wrapper " style={{margin:0}}>

       <MainWrapper>

<AuditSheild>

<div style={{backgroundColor:'#101010',padding:20,borderRadius:15}}>
<InstaShowcase2/>

<AuditSheilds>
  <div>
<h1 style={{ color: '#ffba00', textAlign: 'left',lineHeight:1 }}>Brand Ambassador<br/><span style={{color:'white',fontSize:38}}>Sunny Leone</span></h1>
<p style={{textAlign:'left',margin:0,padding:0,fontSize:'18px !important'}}><br/>Sunny Leone: The Face of Innovation and Trust<br/><br/>
We are proud to have Sunny Leone, a global icon and renowned actress, as the brand ambassador for Baaz Coin. Known for her dynamic personality, trustworthiness, and international appeal, Sunny embodies the spirit of Baaz Coin—bold, innovative, and forward-thinking.
<br/>
Her commitment to excellence and versatility resonates with our mission to revolutionize the blockchain and cryptocurrency space. With Sunny Leone as the face of Baaz Coin, we aim to bridge the gap between cutting-edge technology and the everyday user, making digital currency more accessible and relatable to all.
<br/><br/>
Join us in this journey toward a decentralized future, led by visionaries like Sunny Leone!</p>
<div className="media">
          
              <div
                style={{
                  width: 'fit-content',
                  zIndex:1000,
marginTop:40,
                  height: 40,
                  borderRadius: 10000,
                  padding:'20px',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid rgb(211 211 211 / 28%)",
                }}
              >
                <a href="https://www.instagram.com/sunnyleone">
                  <i className="fab fa-instagram"></i> Instagram
                </a>
              </div>
            </div>
</div>
<img className='imggg' src={LeoneNew} style={{marginBottom:-30}}/>


</AuditSheilds>
<h3 style={{textAlign:'left',marginBottom:15,marginTop:20}}>What people say about us...</h3>
<InstaShowcase/>
</div>

</AuditSheild>
       </MainWrapper>
    </div>
  );
};

export default BlockThree;
