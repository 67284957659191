import React, { useEffect, useState } from "react";
import styled, { css } from 'styled-components';
import { MdContentCopy } from "react-icons/md"; // Icon for copy
import { FiExternalLink } from "react-icons/fi"; // Icon for external link
import { calculateTokens, buyTokens,fetchPresaleDetails,withdrawTokens } from "../../utils/Connectors/Presale";
import { useAccount,useNetwork, useBalance, useChainId, useSwitchNetwork } from "wagmi";
import { useSnackbar, SnackbarProvider } from "notistack";
import { getBalance } from "../../utils/Connectors/Token";
import Progress from "./Progress";
import { useWeb3Modal } from "@web3modal/react";
import firebase from "firebase/app";
import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref as dataRef,
  get,
  push,
  set,
  update,
  Database,
} from "firebase/database";


interface LabeldProps {
  isFocused: boolean;
}

const Label = styled.label`
  display: block;
  margin: 12px 0;
  font-size: 16px;
  color: #fff;

`;

const Labels = styled.label`
  display: block;
  margin: 0px 0 10px;
  font-size: 18px;
  color: #fff;
`;


const InputText = styled.input`
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #333333;
  color: #e0e0e0;
  border: 1px solid #444444;
  border-radius: 10px;
  font-size: 16px;
  margin-top: 5px;
  transition: 0.3s;
  background-color: rgba(38, 38, 38, 0.6); /* #262626 with 10% opacity */
  position: relative; /* For positioning the span inside the input */

  &:focus {
    outline: none;
    border-color: #ffba00;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
  }

 
`;


const Labeld = styled.label<LabeldProps>`
  display: block;
  margin: 12px 0;
  font-size: 16px;
  color: #fff;

  ${(props) =>
    props.isFocused &&
    css`
      .ololol {
    

      }
    `}
  
  .ololol {
    padding: 8px 10px;
    margin-top: 5px;
    background-color: rgba(38, 38, 38, 0.6);
    border: 1px solid #444444;
    border-radius: 10px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
`;

const InputTextd = styled.input`
  display: block;
  width: 100%;
  color: #e0e0e0;
  border: none;
  font-size: 16px;
  margin-top: 5px;
  transition: 0.3s;
  background-color: transparent;

  &:focus {
    outline: none;
  }
`;

const SpanRight = styled.span`
min-width:fit-content;
  font-size: 14px;
  gap:10px;
//   font-style: italic;
    color: #bfbfbf;

   
`;

const TextArea = styled.textarea`
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #333333;
  color: #e0e0e0;
  border: 1px solid #444444;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 15px;
  transition: 0.3s;

  &:focus {
    outline: none;
    border-color: #ffba00;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
  }
`;

const MainWrapper = styled.div`
  padding: 120px 0px;
  max-width: 100%;
  border-radius: 20px;

  h1 {
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 45px;
    font-family: var(--font-bold);
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }

  p {
    text-align: center;
    font-size: 17px;
    color: #ccc;
    margin: 0 30%;

    @media (max-width: 768px) {
      margin: 0;
      font-size: 13px;
    }
  }

  .audit-1 {
    padding: 20px;

    @media (max-width: 768px) {
      padding: 0px;
    }
    h2 {
      font-family: var(--font-bold);

      font-weight: 700;
      text-align: center;
      font-size: 27px;
    }
  }

  .audit-2 {
    padding: 20px;
  }

  .button-select {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: rgba(38, 38, 38, 0.6); /* #262626 with 10% opacity */
    padding: 7px;
    border-radius: 10px;

    .button-selection {
      width: 70px;
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s ease; /* Add smooth animation */
    }

    .button-selection.active {
      background-color: rgba(255, 186, 0, 0.8);
      transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for active state */
    }
  }

  .audio {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .timer {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .time-block {
    background-color: rgba(38, 38, 38, 0.6); /* #262626 with 10% opacity */
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    width: 80px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      width: 40px;
    }
  }

  .time-block span:first-child {
    font-size: 2rem;
    font-weight: bold;
    display: block;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  @media (max-width: 600px) {
    .time-block {
      margin-bottom: 10px;
    }
  }
`;

const Audits = styled.div`
  display: grid;
  margin-top: 20px;
  width: 80%;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  background-color: rgba(38, 38, 38, 0.4);
  border-radius: 20px;
  backdrop-filter: blur(1px);
  border-radius: 15px;
  border: 1px solid #606060;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 20px 0px;
  }

  .auditkyc {
    padding: 35px;
    @media (max-width: 768px) {
      padding: 25px;
    }

    .icon-container {
      width: fit-content;
      padding: 7px;
      display: flex;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      background-color: #303030;
      border: 1px solid #606060;
    }

    img {
      width: 40px;
      height: 40px;
      margin: 0;
      @media (max-width: 768px) {
        width: 30px;
        height: 30px;
      }
    }

    h1 {
      font-size: 26px;
      font-weight: 600;
      font-family: var(--font-normal);
      text-align: left;
      margin-top: 10px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    p {
      padding: 0;
      margin: 0;
      text-align: left;
      font-size: 14px;
      margin-top: 10px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
`;

const BlockThree: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [buyValue, setBuyValue] = useState<string>(""); // State for buy input
  const [usdAmount, setUsdAmount] = useState<number>(0);
  const { open } = useWeb3Modal();
  const [isFocused, setIsFocused] = useState(false);

  const [calculatedTokens, setCalculatedTokens] = useState<string>(""); // State for calculated tokens
  const [errorMessage, setErrorMessage] = useState<string>(""); // State for error messages
  const [isLoading, setIsLoading] = useState<boolean>(false); // State for loading spinner
  const { address } = useAccount();
  const { enqueueSnackbar } = useSnackbar(); // Notistack hook
  const [balance, setBalance] = useState<number | null>(null);
  const [bnbPrice, setBnbPrice] = useState(""); // Placeholder for BNB price
  const [raisedBNB, setRaisedBNB] = useState(""); // Placeholder for BNB price

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const fetchDetails = async () => {
      if (address) {
        try {
          const details = await fetchPresaleDetails(address); // Pass the correct address
          const totalPurchasedUSD = parseFloat(details.totalPurchasedUSD);

          // Perform the calculation
          const raisedBNB = totalPurchasedUSD * Number(bnbPrice);

          // Update the state, ensuring it's a string
          setRaisedBNB(raisedBNB.toString());
        } catch (error) {
          console.error('Error fetching presale details:', error);
        }
      }
    };

    // Fetch immediately and then start interval
    fetchDetails();
    intervalId = setInterval(fetchDetails, 5000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [address, bnbPrice]); // Re-run if address or bnbPrice changes
  
  // Simulate token calculation (replace with actual logic)

  async function fetchBscPairData(pairAddress:string) {
    const apiUrl = `https://openapi.dexview.com/latest/dex/pairs/bsc/${pairAddress}`;
    
    try {
      const response = await fetch(apiUrl);
      
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
      }
      
      const data = await response.json();
  
      // Extract relevant data from the response
      if (data && data.pair) {
        const { baseToken, quoteToken, priceNative, priceUsd, liquidity, txns, volume } = data.pair;
  
  
        console.log("Price (USD):", priceUsd);
        setBnbPrice(priceUsd)
  

      } else {
        console.error("Pair data is missing in the response.");
      }
    } catch (error:any) {
      console.error("Error fetching pair data:", error.message);
    }
  }
  
  // Usage: Pass the pair address as an argument
  fetchBscPairData("0x36696169c63e42cd08ce11f5deebbcebae652050");

  
  const handleBuyChange = async (value: string) => {
    setBuyValue(value.trim());
    setUsdAmount(Number(value) * parseFloat(bnbPrice));

    // Validation for input
    if (!/^\d*\.?\d*$/.test(value)) {
      setErrorMessage("Please enter a valid numeric value.");
      setCalculatedTokens("");
      return;
    }

    setErrorMessage(""); // Clear error messages

    if (!value || Number(value) <= 0) {
      setCalculatedTokens(""); // Leave receive field empty if no valid input
      return;
    }

    if (address) {
      setIsLoading(true); // Show loading spinner
      try {
        const tokens = await calculateTokens(Number(value), address);
        setCalculatedTokens(tokens.toString());
      } catch (error) {
        console.error("Error calculating tokens:", error);
        setErrorMessage("Failed to calculate tokens. Please try again.");
        setCalculatedTokens("");
      } finally {
        setIsLoading(false); // Hide loading spinner
      }
    } else {
      console.error("Wallet address is undefined");
      setErrorMessage("Please connect your wallet.");
      setCalculatedTokens("");
    }
  };

  useEffect(() => {
    const fetchBalance = async () => {
      if (address) { // Check if address is defined
        try {
          const tokenBalance = await getBalance(address);
          setBalance(tokenBalance);
        } catch (error) {
          console.error("Error fetching balance:", error);
        }
      } else {
        console.error("Address is not defined");
      }
    };
  
    fetchBalance(); // Initial balance fetch
    
    const intervalId = setInterval(fetchBalance, 5000); // Update every 5 seconds
    
    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [address]); // Dependencies: only re-run when address changes
  
  const { switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();



  const [isPurchasing, setIsPurchasing] = useState<boolean>(false); // State for purchase button



  const { data:balanceNative } = useBalance({
    address: address,
  });
  const handleMaxClick = async () => {
    try {
      // Check if balanceNative exists and is a valid value before setting the buyValue
      if (balanceNative && balanceNative.formatted) {
        const reducedValue = parseFloat(balanceNative.formatted) * 0.95; // 5% less
        setBuyValue(reducedValue.toString()); // Set the reduced value
        setUsdAmount(Number(reducedValue) * parseFloat(bnbPrice));
  if(address){
        // Ensure calculateTokens is called correctly with await
        const tokens = await calculateTokens(Number(reducedValue), address);
        setCalculatedTokens(tokens.toString());}
      }
    } catch (error) {
      console.error('Error in handleMaxClick:', error);
    }
  };
  
  
  const [fullTxnHash, setFullTxnHash] = useState<string>(""); // State for transaction hash

  const [txnHash, setTxnHash] = useState<string>(""); // State for transaction hash
  const [showTxn, setShowTxn] = useState<boolean>(false); // State for displaying transaction hash
  const firebaseConfig = {
    apiKey: "AIzaSyAsVaHRQ_4McPs_pW9gMk61RQwiiC8qcdc",
    authDomain: "website-d6cc3.firebaseapp.com",
    databaseURL: "https://website-d6cc3-default-rtdb.firebaseio.com",
    projectId: "website-d6cc3",
    storageBucket: "website-d6cc3.firebasestorage.app",
    messagingSenderId: "194064369274",
    appId: "1:194064369274:web:ca34d24b47d5fb312d5dc3",
    measurementId: "G-4636V1PSK9"
    
      };
      const app = initializeApp(firebaseConfig);
      const database: Database = getDatabase(initializeApp(firebaseConfig));
    
      const newUserRef = push(dataRef(database, `buyings/`));



  const handlePurchase = async () => {
    if (!buyValue || Number(buyValue) <= 0) {
      enqueueSnackbar("Please enter a valid BNB amount to purchase tokens.", {
        variant: "error",
      });
      return;
    }
  
    if (!address) {
      enqueueSnackbar("Please connect your wallet to purchase tokens.", {
        variant: "error",
      });
      return;
    }
  
    setIsPurchasing(true);
    enqueueSnackbar("Processing your purchase...", { variant: "info" });
    try {
      const txnResponse = await buyTokens(buyValue, address); // Assuming buyTokens returns the transaction response
  

      const txnHistory = {
        address: address,
        txnHash: txnResponse,
        buy: buyValue,
        usd: usdAmount,
        received: calculatedTokens,
        time: new Date().toISOString(),
      };
      
      // Filter out `NaN` values
      const filteredTxnHistory = Object.fromEntries(
        Object.entries(txnHistory).filter(([key, value]) => 
          typeof value !== 'number' || !isNaN(value)
        )
      );
      
      console.log(filteredTxnHistory);
      
      set(newUserRef,txnHistory)
  
      setBuyValue("");
      setCalculatedTokens("");
      const shortTxnHash = `${txnResponse.slice(0, 14)}...${txnResponse.slice(-8)}`;
      setTxnHash(shortTxnHash); // Save shortened transaction hash
      setFullTxnHash(txnResponse); // Save shortened transaction hash

            setShowTxn(true); // Show transaction hash section
  console.log(txnResponse)
  enqueueSnackbar("Purchase completed successfully!", {
    variant: "success",
  });
      // Hide the transaction hash after 10 seconds
      setTimeout(() => setShowTxn(false), 10000);
    } catch (error) {
      console.error("Error during purchase:", error);
      enqueueSnackbar("Purchase failed. Please try again.", { variant: "error" });
    } finally {
      setIsPurchasing(false);
    }
  };
  
  // Copy transaction hash to clipboard
  const copyToClipboard = (hash: string) => {
    navigator.clipboard.writeText(hash);
    enqueueSnackbar("Transaction hash copied to clipboard!", { variant: "success" });
  };

  const [selected, setSelected] = useState("crypto");

  const handleSelection = (option: string) => {
    setSelected(option);
  };

  useEffect(() => {
    const targetDate = new Date("2024-11-29T18:40:00Z").getTime();

    const updateTimer = () => {
      const now = new Date();
      const timeDifference = targetDate - now.getTime();

      if (timeDifference <= 0) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        clearInterval(intervalId);
        return;
      }

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    };

    const intervalId = setInterval(updateTimer, 1000);
    updateTimer(); // Initial call to set time immediately

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);


  const handleWithdraw = async () => {
    setIsLoading(true);
          

    try {
      if(address){
      // Call the withdrawTokens function
      const result = await withdrawTokens(address);
      console.log("Withdraw Result:", result);}
    } catch (err: unknown) {
      if (err instanceof Error) {
      } else {
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
 <>
        
        <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <h3 style={{ fontSize: 14, marginLeft: 5, marginBottom: 3 }}>
                  Softcap : <span style={{ color: "#ffba00" }}>$440K</span>
                </h3>
                <h3
                  style={{
                    fontSize: 14,
                    marginRight: 5,
                    marginBottom: 3,
                    textAlign: "right",
                  }}
                >
                  Hardcap : <span style={{ color: "#ffba00" }}>$4.5M</span>
                </h3>
              </div>
              <Progress progress={51.1+Number(raisedBNB)*100/4500000} />

       
              <div
                style={{
                  marginTop: 20,
                  backgroundColor: "rgba(38, 38, 38, 0.6)",
                  padding: 13,
                  borderRadius: 10,
                }}
              >
                <Labels>Presale Info</Labels>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    alignItems: "center",
                  }}
                >
                  <h3 style={{ fontSize: 14, marginBottom: 5 }}>
                    Presale Rate :{" "}
                  </h3>
                  <h3
                    style={{
                      fontSize: 14,
                      marginBottom: 5,
                      textAlign: "right",
                    }}
                  >
                    <span style={{ color: "#ffba00" }}>$ 0.07</span>
                  </h3>

                  <h3 style={{ fontSize: 14, marginBottom: 5 }}>
                    Balance (BAAZ) :{" "}
                  </h3>
                  <h3
                    style={{
                      fontSize: 14,
                      marginBottom: 5,
                      textAlign: "right",
                    }}
                  >
                    <span style={{ color: "#ffba00" }}>{balance?.toFixed(4)} BAAZ</span>
                  </h3>

                  <h3 style={{ fontSize: 14, marginBottom: 5 }}>
                    Balance (BNB) :{" "}
                  </h3>
                  <h3
                    style={{
                      fontSize: 14,
                      marginBottom: 5,
                      textAlign: "right",
                    }}
                  >
         <span style={{ color: "#ffba00" }}>
  {new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 3,
  }).format(Number(balanceNative?.formatted ?? 0))} {balanceNative?.symbol}
</span>

                  </h3>

                  <h3 style={{ fontSize: 14, marginBottom: 5 }}>
                    Total Raised :{" "}
                  </h3>
                  <h3
                    style={{
                      fontSize: 14,
                      marginBottom: 5,
                      textAlign: "right",
                    }}
                  >
<span style={{ color: "#ffba00" }}>
  {Number(raisedBNB) > 0 ? (
    `$${(411000 + Number(raisedBNB || 0)).toFixed(2)} / $4500000`
  ) : (
   ` $0`
  )}
</span>

                  </h3>
                </div>
              </div>

              <Labeld isFocused={true}>
  Buy Amount (BNB):
  <div className="ololol" style={{ display:'flex',justifyContent:'space-between',alignItems:'center' }}>
    <InputTextd
      type="text"
      value={buyValue}
      aria-invalid={!!errorMessage}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      aria-describedby="buy-error"
      placeholder="Enter BNB amount"
      onChange={(e) => handleBuyChange(e.target.value)} // Update state on input change
    />
    <SpanRight>
      {Number(buyValue) > 0 && usdAmount !== undefined && usdAmount !== null && (
        <span>~$ {usdAmount.toFixed(2)}</span>
      )}
      <button
        style={{
          backgroundColor: '#ffc107',
          padding: '3px 9px',
          cursor: 'pointer',
          border: 'none',
          marginLeft:10,
          borderRadius: 3,
          fontFamily: 'var(--font-medium)',
        }}
        onClick={handleMaxClick}
      >
        MAX
      </button>
    </SpanRight>
  </div>
</Labeld>




             
              <Label>
                Receive Amount (BAAZ):
                <InputText
                  type="text"
                  disabled
                  value={
                    isLoading
                      ? "Calculating..."
                      : Number(calculatedTokens).toFixed(2)
                  } // Show spinner if loading
                  aria-live="polite"
                  placeholder="--"
                />
              </Label>

              <button
                style={{
                  width: "100%",
                  padding: "10px",
                  border: "none",
                  backgroundColor:isPurchasing ? "#bf9518" : "#ffc107",
                  cursor: isPurchasing ? "not-allowed" : "pointer",
                  fontSize: "16px",
                }}
                className={`button-prim ${isPurchasing ? "disabled" : ""}`}
                onClick={() => {
                  if (address) {
                    if (chain?.id === 56) {
                      handlePurchase();
                    } else {
                      switchNetwork?.(56);
                    }
                  } else {
                    open();
                  }
                }}
                
                disabled={isPurchasing}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 0,
                    padding: 0,
                  }}
                >
{isPurchasing 
  ? "Processing..." 
  : address 
    ? (chain?.id === 56 
        ? "Purchase" 
        : "Switch Network to BSC") 
    : "Connect Wallet"}
                </div>
              </button>

  
     {showTxn && txnHash && (
  <div style={{ marginTop: "15px", textAlign: "center" }}>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        backgroundColor: "#262626",
        padding: "10px",
        borderRadius: "8px",
        color: "#fff",
      }}
    >
      <span style={{ fontSize: "14px" }}>{txnHash}</span>
      <button
        onClick={() => copyToClipboard(fullTxnHash)}
        style={{
          background: "none",
          border: "none",
          color: "#ffba00",
          cursor: "pointer",
        }}
      >
        <MdContentCopy size={20} />
      </button>

    

      <a
        href={`https://bscscan.com/tx/${fullTxnHash}`} // Replace with your network's explorer URL
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#ffba00" }}
      >
        <FiExternalLink size={20} />
      </a>
    </div>
  </div>
)}

            </>
  );
};

export default BlockThree;
