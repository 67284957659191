import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import styled, { css } from 'styled-components';

import room from '../../assets/images/staking.png'

const MainWrapper = styled.div`
  margin: 0px 0px;
  max-width: 100%;
  border-radius: 20px;
  padding: 20px;
  grid-template-columns: 20% 80%;
  
`;

const Audits = styled.div`
  display: grid;
  margin-top: 20px;
  width: 100%;
  padding: 20px 200px;
  grid-template-columns: 1fr 1fr;
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 20px 00px;

  }
`;

const AuditImage = styled.img`
  margin-bottom: 20px;
  width: 70%;
  margin-right:50px;
`;

const AuditContents = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  margin-left: 20px;
`;

const AuditContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;
`;

const Title = styled.h1`
  color: #fff;
  font-weight: 500;
  font-size: 35px;
  font-family: var(--font-bold);

  span {
    color: #ffba00;
  }

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const Description = styled.p`
  font-size: 17px;
  color: #ccc;
  margin: 20px 0;
`;



const BlockThree: React.FC = () => {
  return (
    <div className="blockthree-wrapper apoplps" style={{ margin: 0 }}>
      <MainWrapper>
        <Audits>
          <AuditContents>
          <AuditImage src={room} />

          </AuditContents>

          <AuditContent>
            <Title>Stake and <span>Earn</span></Title>
            <Description>
            At Baaz, we’re transforming the way you stake and earn by making the process seamless and rewarding. Our innovative staking system allows you to earn passive rewards effortlessly, ensuring simplicity, security, and profitability. Whether you’re staking tokens or participating in liquidity pools, our platform integrates advanced blockchain technology to enhance your earnings. Say goodbye to complicated processes and enjoy a future-ready way to grow your crypto. Stake, earn, and take control—simplified with Baaz.</Description>            
                    <button

onClick={() => window.location.href = "/staking"}
            className={`button-prim `}
            style={{width:'fit-content',cursor:'pointer'}}
          >
            <div style={{display:'flex',fontSize:16,justifyContent:'center',alignItems:'center',margin:0,padding:0}}>
Stake Now           </div>
          </button>
          </AuditContent>

        </Audits>
      </MainWrapper>
    </div>
  );
};

export default BlockThree;
