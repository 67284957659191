import React, { useState } from "react";
import styled from "styled-components";
import Hero from "../../components/Hero";
import BlockTwo from '../../components/BlockTwo';
import BlockThree from "../../components/BlockThree";
import BlockFour from "../../components/BlockFour";
import BlockFive from "../../components/BlockNinteen";
import BlockSix from "../../components/BlockSix";
import BlockSeven from "../../components/BlockSeven";
import BlockEight from "../../components/BlockEight";
import BlockNine from "../../components/BlockNine"
import BlockTen from "../../components/BlockTen"
import BlockEleven from "../../components/BlockEleven"
import BlockSeventeen from "../../components/BlockSeventeen"
import BlockEighteen from "../../components/BlockEighteen"

import BlockTwentyTwo from "../../components/BlockTwentyTwo"
import BlockTwentyFive from "../../components/BlocvkTwentyFive"



const Home: React.FC = () => {

  
 
  return (
    <div>
      <div>
      <div id="hero">
          <Hero />
        </div>   
        <div id="buy">
        <BlockFive/>
        </div>      
        <div id="about">
          <BlockThree />
        </div>      <BlockTwentyFive/>  <BlockSeventeen/> <div id="work"> <BlockFour /></div> <div id="tokenomics"><BlockSeven/></div>
        <div id="roadmap">  <BlockSix/></div><BlockEight/><BlockTwentyTwo/><div id="team"><BlockNine/></div><div id="partners"><BlockTen/></div><BlockEighteen/><BlockEleven/>
     {/* <TransactionRow />
<AuditHome /> */}
      </div>
      </div>
  );
};

export default Home;
