import React, { useEffect, useState } from "react";
import { useDarkMode } from "usehooks-ts";
import Logo from "../../assets/images/sidebarLogo.png";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import { ReactComponent as MenuIcon2 } from "../../assets/icons/menu2.svg";

import { ReactComponent as MoonIcon } from "../../assets/icons/moon.svg";
import { ReactComponent as SunIcon } from "../../assets/icons/sun.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as Live } from "../../assets/icons/live.svg";
import { ReactComponent as Pair } from "../../assets/icons/liveexplorer.svg";
import { AnimatePresence, motion } from "framer-motion";
import { Link, NavLink, useLocation } from "react-router-dom";
import Menu from "../../assets/images/menu.png";
import BitChain from "../../assets/images/LogoImg.png";
import PayWing from "../../assets/images/paywing.png";

import "./Navigation.scss";
import Dropdown from "../Dropdown";
import Loogo from "../../assets/images/logo-white.png";

import styled from "styled-components";

const Button = styled.button`
  background-color: #0a090256;
  color: #ffba00;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow */
  transform: translateY(0px); /* Reset the translateY */
  transition: transform 0.2s, box-shadow 0.2s; /* Add smooth transition effects */

  &:hover {
    transform: translateY(-2px); /* Move the button up slightly on hover */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); /* Increase the box shadow on hover for a more pronounced 3D effect */
  }
`;

const Navigation: React.FC = () => {
  const { isDarkMode, toggle } = useDarkMode();
  const [openClose, setOpenClose] = useState(false);
  const [openCloses, setOpenCloses] = useState(false);

  const [activeLink, setActiveLink] = useState("");
  const [openDropdown, setOpenDropdown] = useState(false);
  const location = useLocation();

  // Get the current location using useLocation hook

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Get the current URL pathname
    const currentUrlPathname = window.location.pathname;

    // Set the active link based on the current URL pathname
    setActiveLink(currentUrlPathname);
  }, []);

  useEffect(() => {
    if (openClose) {
      document.body.style.overflowY = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflowY = "initial";
      document.body.style.height = "initial";
    }
  }, [openClose]);

  useEffect(() => {
    if (isDarkMode) {
      if (document.body.classList.contains("light")) {
        document.body.classList.remove("light");
      }
      document.body.classList.add("dark");
    } else {
      if (document.body.classList.contains("dark")) {
        document.body.classList.remove("dark");
      }
      document.body.classList.add("light");
    }
  }, [isDarkMode]);

  const [activeSection, setActiveSection] = useState("home");

  useEffect(() => {
    const sections = [
      "about",
      "tokenomics",
      "work",
      "roadmap",
      "team",
      "partners",
    ]; // Add more section IDs if needed
    const observer = new IntersectionObserver(
      (entries) => {
        let foundActive = false;

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
            foundActive = true;
          }
        });

        // Default to "home" only if no other section is active
        if (!foundActive) {
          setActiveSection("home");
        }
      },
      {
        root: null, // Viewport as the container
        rootMargin: "0px", // No margin
        threshold: 0.5, // Adjust as needed
      }
    );

    sections.forEach((id) => {
      const target = document.getElementById(id);
      if (target) observer.observe(target);
    });

    return () => {
      sections.forEach((id) => {
        const target = document.getElementById(id);
        if (target) observer.unobserve(target);
      });
    };
  }, []);

  document.addEventListener("scroll", () => {
    const navigation = document.querySelector(".navigation-wrapper")!;
    if (window.scrollY > 0) {
      navigation.classList.add("scrolled");
    } else {
      navigation.classList.remove("scrolled");
    }
  });

  return (
    <div className="navigation-wrapper">
      <div className="mx">
        <div className="desktophead">
          <div>
            {" "}
            <img src={Loogo} className="logoo" />
          </div>
          <div className="site-nav">
            {" "}
            <nav className="site-navigation">
              <ul className="nav">
                <li>
                  <Link
                    to="/"
                    className={`navlink ${
                      activeSection === "home" ? "active" : ""
                    }`}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="./#about"
                    className={`navlink ${
                      activeSection === "about" ? "active" : ""
                    }`}
                    onClick={() => {
                      const section = document.querySelector("#about");
                      section?.scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    About Us
                  </Link>
                </li>
                {/* <li>
      <Link
    to="./#ecosystem"
    className={`navlink ${activeSection === "ecosystem" ? "active" : ""}`}
    onClick={() => {
      const section = document.querySelector("./#ecosystem");
      section?.scrollIntoView({ behavior: "smooth" });
    }}
  >
    Ecosystem
  </Link>
      </li> */}

                <li>
                  <Link
                    to="./#tokenomics"
                    className={`navlink ${
                      activeSection === "tokenomics" ? "active" : ""
                    }`}
                    onClick={() => {
                      const section = document.querySelector("#tokenomics");
                      section?.scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    Tokenomics
                  </Link>
                </li>

                <li>
                  <Link
                    to="./#roadmap"
                    className={`navlink ${
                      activeSection === "roadmap" ? "active" : ""
                    }`}
                    onClick={() => {
                      const section = document.querySelector("#roadmap");
                      section?.scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    Roadmap
                  </Link>
                </li>

                <li>
                  <Link
                    to="./#team"
                    className={`navlink ${
                      activeSection === "team" ? "active" : ""
                    }`}
                    onClick={() => {
                      const section = document.querySelector("#team");
                      section?.scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    Team
                  </Link>
                </li>

                <li>
                  <Link
                    to="./#partners"
                    className={`navlink ${
                      activeSection === "partners" ? "active" : ""
                    }`}
                    onClick={() => {
                      const section = document.querySelector("#partners");
                      section?.scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    Partners
                  </Link>
                </li>

                <li>
                  <Link
                    to="./staking"
                    className={`navlink ${
                      activeSection === "staking" ? "active" : ""
                    }`}
                    onClick={() => {
                      const section = document.querySelector("/staking");
                      section?.scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    Staking
                  </Link>
                </li>

                <li>
                  <Link className={`navlinks `} to="hiring" >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      We are Hiring!
                      <img src={PayWing} style={{ marginLeft: 5 }} width={40} />
                    </div>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div>
            <button   onClick={() => window.location.href = "./#buy"}
 className={`button-prim `}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                  padding: 0,
                }}
              >
                Buy Now
              </div>
            </button>{" "}
          </div>
        </div>

        <div className="mobilehead">
          <div>
            {" "}
            <img src={Loogo} className="logoo"   />
          </div>

          <div>
          <div style={{display:'flex',justifyContent:'end'}}>
            <button   onClick={() => window.location.href = "./#buy"}
 className={`button-prim `} style={{padding:'8px'}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                  padding: 0,
                }}
              >
                Buy Now
              </div>
            </button>{" "}
          </div>
          </div>
          <div>
            {" "}
            <div
              className="circlebg newmwnu"
              onClick={() => setOpenClose((m) => !m)}
            >
              <MenuIcon />
            </div>
          </div>
        </div>
        <AnimatePresence>
          {openClose && (
            <motion.div
              className="sidebar-backdrop"
              onClick={() => setOpenClose(false)}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            >
              <motion.div
                className="bar"
                onClick={(e: any) => e.stopPropagation()}
                animate={{ right: 0, transitionDelay: "-200ms" }}
                exit={{ right: -300 }}
                initial={{ right: -300 }}
              >
                <div className="header-side-bar">
                  <div
                    className="close-icon"
                    onClick={() => setOpenClose(false)}
                  >
                    <Close />
                  </div>

                  <div style={{ flex: 1 }}>
                    <nav>
                      <NavLink
                        to="./#home"
                        className="list flex-item"
                        onClick={() => {
                          const section = document.querySelector("#home");
                          section?.scrollIntoView({ behavior: "smooth" });
                          setOpenClose(false);
                        }}
                      >
                        <span>Home</span>
                      </NavLink>
                    
                    
                      <NavLink
                        to="./#about"
                        className="list flex-item"
                        onClick={() => {
                          const section = document.querySelector("#about");
                          section?.scrollIntoView({ behavior: "smooth" });
                          setOpenClose(false);
                        }}
                      >
                        <span>About Us</span>
                      </NavLink>

                      <NavLink
                        to="./#tokenomics"
                        className="list flex-item"
                        onClick={() => {
                          const section = document.querySelector("./#tokenomics");
                          section?.scrollIntoView({ behavior: "smooth" });
                          setOpenClose(false);
                        }}
                      >
                        <span>Tokenomics</span>
                      </NavLink>

                      <NavLink
                        to="./#roadmap"
                        className="list flex-item"
                        onClick={() => {
                          const section = document.querySelector("#roadmap");
                          section?.scrollIntoView({ behavior: "smooth" });
                          setOpenClose(false);
                        }}
                      >
                        <span>Roadmap</span>
                      </NavLink>

                      <NavLink
                        to="./#partners"
                        className="list flex-item"
                        onClick={() => {
                          const section = document.querySelector("#partners");
                          section?.scrollIntoView({ behavior: "smooth" });
                          setOpenClose(false);
                        }}
                      >
                        <span>Partners</span>
                      </NavLink>

                      

                      <NavLink
                        to="./#team"
                        className="list flex-item"
                        onClick={() => {
                          const section = document.querySelector("#team");
                          section?.scrollIntoView({ behavior: "smooth" });
                          setOpenClose(false);
                        }}
                      >
                        <span>Team</span>
                      </NavLink>
                      <NavLink
                        to="./staking"
                        className="list flex-item"
                        onClick={() => {
                          const section = document.querySelector("staking");
                          section?.scrollIntoView({ behavior: "smooth" });
                          setOpenClose(false);
                        }}
                      >
                        <span>Staking</span>
                      </NavLink>
<hr className="custom-hr" style={{marginTop:20}} />
                      <Link className={`navlinks `} to="hiring">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 0,
                        fontSize:16,
                        marginLeft:20,
                        marginTop:10,
                        padding: 0,
                      }}
                    >
                      We are Hiring!
                      <img src={PayWing} style={{ marginLeft: 5 }} width={40} />
                    </div>
                  </Link>
                    </nav>
                    <div className="allChains"></div>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Navigation;
