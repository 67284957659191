import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAccount,useSwitchNetwork,useNetwork } from "wagmi";
import { useSnackbar } from "notistack";
import { FaWallet } from "react-icons/fa"; // Import wallet icon from Font Awesome
import { useWeb3Modal } from "@web3modal/react";

import { getBalance } from "../../utils/Connectors/Token";
import { Web3Button } from "@web3modal/react";
import Timer from "./Timer";
import {
  getAPY,
  getTotalStaked,
  getStakeCount,
  getStakeDetails,
  stakeTokens,
  claimReward,
  getLockPeriod,
  getRewardCycle,
  claimAllRewards,
  getTotalUser,
  timeLeftForClaim,
  timeLeftForWithdrawal,
  fetchRewards,
  fetchRewardsByStakeId,
  withdrawStake,
} from "../../utils/Connectors/Staking";

// Styled components for dark theme
const Containers = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: #121212;

  @media (max-width: 768px) {
    padding-top: 100px;
    padding-bottom: 30px;
  }
`;

const Container = styled.div`
  padding: 30px;
  max-width: 1200px;
  margin: auto;
  background: #181818;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  color: white;

  @media (max-width: 768px) {
    padding: 20px;
    border-radius: 12px;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Spinner = styled.div`
  border: 4px solid rgba(215, 215, 215, 0.5);
  border-left-color: #ffba00;
  border-radius: 50%;
  width: 24px;
  margin: 10px;
  height: 24px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const Containero = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
margin:0px 0 20px;
width:100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap:10px;
  }
`;

const ModalContent = styled.div`
  background: #2a2a2a;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h3 {
    margin-bottom: 0px;
    color: #d7d7d7;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  color: white;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Seccc = styled.div`
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }
`;

const Box = styled.div`
  flex: 1;
  padding: 20px;
  background: #2a2a2a;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  line-height: 1.4;
  font-weight: bold;
  color: white;

  span {
    color: #ffba00;
    font-size: 15px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 15px;
  }
`;

const Section = styled.div`
  margin-top: 30px;
  color: white;
`;

const SectionStake = styled.div`
  margin-top: 20px;
  color: white;
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #2a2a2a;
  padding: 20px;
  border-radius: 12px;

  @media (max-width: 768px) {
    padding: 40px 13px 20px;
  }
`;

const StakeInput = styled.div`
  display: flex;
  width: 80%;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
  color: white;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin-top: 0px;

    gap: 10px;
  }
`;

const StakeButton = styled.button`
  background-color: #ffc107;
  color: #000;
  padding: 10px 25px;
  border: none;
  font-family: var(--font-normal);
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;

  &:hover {
    background: #ff9800;
  }

  &:disabled {
    background: #555;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px 20px;
  }
`;

const StakeButtons = styled.button`
  background-color: #ffc107;
  color: #000;
  padding: 10px 25px;
  border: none;
  font-family: var(--font-normal);
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;

  &:hover {
    background: #ff9800;
  }

  &:disabled {
    background: #555;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 7px 15px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin: 12px 0;
  border: 1px solid #555;
  border-radius: 8px;
  background: #333;
  color: white;
  font-size: 16px;
  outline: none; /* Remove default focus outline */

  /* Remove up/down spinner for number input */
  -webkit-appearance: none;
  -moz-appearance: textfield;

  /* Hide spinner in Webkit browsers */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Add custom focus outline */
  &:focus {
    border: 1px solid #a48940; /* Change border color on focus */
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
  }
`;

const Button = styled.button`
  background-color: #ffc107;
  color: #000;
  padding: 10px 25px;
  border: none;
  font-family: var(--font-normal);
  border-radius: 50px;
  cursor: pointer;
  margin-top: 12px;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;

  &:hover {
    background: #ff9800;
  }

  &:disabled {
    background: #555;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px 20px;
  }
`;

const Card = styled.div`
  background: #2a2a2a;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Cardss = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 0px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const CardRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
    flex-direction:column;
    align-items: start;

  }
`;

const CardRoww = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
    flex-direction:column;
    align-items: start;
  }
`;

const StakingDashboard = () => {
  const { address } = useAccount();
  const { enqueueSnackbar } = useSnackbar();
  const [apy, setAPY] = useState<number>(0);
  const [user, setUser] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [stakeProcess, setStakeProcess] = useState<boolean>(false);
  const [claimProcess, setClaimProcess] = useState<boolean>(false);
  const [withdrawProcess, setWithdrawProcess] = useState<boolean>(false);
  const { open } = useWeb3Modal();
  const { switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();
  const [reward, setRewards] = useState<number>(0);
  const [totalStaked, setTotalStaked] = useState<number>(0);
  const [stakeAmount, setStakeAmount] = useState<string>("");
  const [stakes, setStakes] = useState<
    {
      id: string;
      date: string;
      amount: number;
      lastClaimTime: number;
      canWithdraw: boolean;
      timeLeft: number;
      timeLeftWith: number;
      reward: number;
    }[]
  >([]);

  const fetchDetails = async () => {
    try {
      if (!address) return;
      if (chain?.id !== 56) return;

      const [apyValue, totalStakedValue, stakeCount, fetchReward, user,balance] =
        await Promise.all([
          getAPY(),
          getTotalStaked(),
          getStakeCount(address),
          fetchRewards(address),
          getTotalUser(),
          getBalance(address)
        ]);
      setAPY(Number(apyValue));
      setTotalStaked(Number(totalStakedValue));
      setRewards(Number(fetchReward));
      setUser(Number(user));
      setBalance(Number(balance));

      const stakeDetailsPromises = Array.from({ length: stakeCount }).map(
        (_, index) => getStakeDetails(address, index)
      );

      const timeLeft = Array.from({ length: stakeCount }).map((_, index) =>
        timeLeftForClaim(address, index)
      );

      const timeLefts = await Promise.all(timeLeft);

      const timeLeftWith = Array.from({ length: stakeCount }).map((_, index) =>
        timeLeftForWithdrawal(address, index)
      );

      const timeLeftsWith = await Promise.all(timeLeftWith);

      const reward = Array.from({ length: stakeCount }).map(
        async (_, index) => {
          try {
            return await fetchRewardsByStakeId(address, index);
          } catch (error) {
            return 0; // Return 0 in case of an error
          }
        }
      );

      const rewards = await Promise.all(reward);

      const stakeDetails = await Promise.all(stakeDetailsPromises);

      // Map the fetched stake details to the expected format
      const formattedStakes = stakeDetails.map((stake, index) => ({
        id: index.toString(),
        date: stake.startTime,
        amount: Number(stake.amount),
        lastClaimTime: stake.lastClaimTime,
        canWithdraw: stake.withdrawn,
        timeLeftWith: Number(timeLeftsWith[index]), // Merge timeLefts with formattedStakes
        reward: Number(rewards[index]),
        timeLeft: Number(timeLefts[index]), // Merge timeLefts with formattedStakes
      }));

      setStakes(formattedStakes);
    } catch (error) {
      enqueueSnackbar("Failed to fetch details", { variant: "error" });
    }
  };

  useEffect(() => {
    fetchDetails(); // Initial fetch
    const intervalId = setInterval(fetchDetails, 3000); // Set interval to fetch every 3 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [address]); // Re-fetch when address changes

  const handleStake = async () => {
    if (
      !stakeAmount ||
      isNaN(Number(stakeAmount)) ||
      Number(stakeAmount) <= 0
    ) {
      enqueueSnackbar("Invalid stake amount", { variant: "warning" });
      return;
    }
    try {
      if (address) {
        setStakeProcess(true)

        await stakeTokens(address, stakeAmount);
        enqueueSnackbar("Staking successful!", { variant: "success" });
        setStakeAmount("")
        fetchDetails();
      }
    } catch (error) {
      enqueueSnackbar("Staking failed", { variant: "error" });
    }
    finally {
      setStakeProcess(false); // Hide claiming popup
    }
  };

  const handleClaim = async (stakeId: string) => {
    try {
      if (address) {
setClaimProcess(true)
        await claimReward(address, parseInt(stakeId));
        enqueueSnackbar("Reward claimed successfully!", { variant: "success" });
        fetchDetails();

      }
    } catch (error) {
      enqueueSnackbar("Claim failed", { variant: "error" });
    }
    finally {
      setClaimProcess(false); // Hide claiming popup
    }
  };

  const handleClaimAll = async () => {
    try {
      if (address) {
        setClaimProcess(true)

        await claimAllRewards(address);
        enqueueSnackbar("All rewards claimed successfully!", {
          variant: "success",
        });
        fetchDetails();
      }
    } catch (error) {
      enqueueSnackbar("Claim all failed", { variant: "error" });
    }
    finally {
      setClaimProcess(false); // Hide claiming popup
    }
  };

  const handleWithdraw = async (stakeId: string) => {
    try {
      if (address) {
        setWithdrawProcess(true); // Hide claiming popup

        await withdrawStake(address, parseInt(stakeId));
        enqueueSnackbar("Stake withdrawn successfully!", {
          variant: "success",
        });
        fetchDetails();
      }
    } catch (error) {
      enqueueSnackbar("Withdrawal failed", { variant: "error" });
    }
    finally {
      setWithdrawProcess(false); // Hide claiming popup
    }
  };

  return (
    <Containers>
      {!address ? (<Container style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',height:'40vh'}}>Please Connect Wallet to Proceed<br/><Button onClick={open}>Connect Wallet</Button></Container>):(
        <>
        {chain?.id === 56 ? (
      <Container>
        <Containero
        
        >
          <h2>BAAZ Staking Pool</h2>

          <Web3Button />
        </Containero>

        <Header>
          <Box>
            {apy}%<br />
            <span>APY </span>
          </Box>
          <Box>
            {" "}
            {totalStaked} BAAZ <br />
            <span>Total Staked </span>
          </Box>
          <Box>
            {" "}
            {user} <br />
            <span>Total Stakers </span>
          </Box>
        </Header>

        <SectionStake>
        <div style={{position:'absolute',top:10,right:10,display:'flex',gap:4,alignItems:'center',justifyContent:'center',fontSize: "14px", color: "white" ,margin:0,padding:0}}>
      <div><FaWallet style={{ fontSize: "12px", color: "white" ,margin:0,padding:0}} /></div>  
  {balance.toFixed(3)} BAAZ
          </div>
          <h2>Stake BAAZ Coin</h2>
          <StakeInput>
            <Input
              type="number"
              placeholder="Enter amount to stake"
              value={stakeAmount}
              onChange={(e) => setStakeAmount(e.target.value)}
            />
            <StakeButton
              onClick={handleStake}
              disabled={!address || !stakeAmount}
            >
              Stake
            </StakeButton>
          </StakeInput>
        </SectionStake>

        <Section>
    
          <Seccc>
            <h2>Your Stakes</h2>
            {reward === 0 ? (<></>):( <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <h4 style={{ lineHeight: 1.3 }}>
                <span
                  style={{ color: "#ffba00", fontSize: 12, lineHeight: 0.5 }}
                >
                  Total Reward:
                </span>
                <br />
                {reward.toFixed(7)} BAAZ
              </h4>

              <StakeButtons
                onClick={handleClaimAll}
                disabled={stakes.length === 0 || reward === 0}
              >
                Claim All Rewards
              </StakeButtons>
            </div>)}
           
          </Seccc>

          <Cardss>
  {stakes.length > 0 ? (
    stakes
    .sort((a, b) => (a.canWithdraw === b.canWithdraw ? 0 : a.canWithdraw ? 1 : -1))
    .map((stake, index) => (
        <Card
          key={index}
          style={{
            opacity: stake.canWithdraw ? 0.7 : 1,
          }}
        >
          <CardRow>
            <div>Stake ID: {stake.id}</div>
            <div>
              {new Date(Number(stake.date) * 1000)
                .toLocaleString("en-GB", {
                  day: "2-digit",
                  month: "short", // Abbreviated month name
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true, // 12-hour format
                })
                .replace(",", "")
                .toUpperCase()}{" "}
              {/* Remove the comma */}
            </div>
          </CardRow>
          <CardRow>
            <div>Amount: <span style={{color:'#ffba00'}}>{stake.amount}</span> BAAZ</div>
            <div>Reward: {stake.reward.toFixed(7)} BAAZ</div>
          </CardRow>

          <CardRoww>
            <div style={{ height: "fit-content" }}>
              {stake.timeLeft <= 0 ? (
                <Button
                  onClick={() => handleClaim(stake.id)}
                  disabled={stake.canWithdraw}
                >
                   Claim
                </Button>
              ) : (
                <Timer
                  timeLeft={stake.timeLeft}
                  title="Time left to Claim"
                />
              )}
            </div>
            <div style={{ height: "fit-content" }}>
              {stake.timeLeftWith <= 0 ? (
                <Button
                  onClick={() => handleWithdraw(stake.id)}
                  disabled={stake.canWithdraw}
                >
                  {stake.canWithdraw ? "Already Withdrawn" : "Withdraw"}
                </Button>
              ) : (
                <Timer
                  timeLeft={stake.timeLeftWith}
                  title="Time left to Withdraw"
                />
              )}
            </div>
          </CardRoww>
        </Card>
      ))
  ) : (
    <p>No stakes found</p>
  )}
</Cardss>

        </Section>
      </Container>
      ):(<Container
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '40vh',
        }}
      >
        Please Switch to BSC Smart Chain to Proceed
        <br />
        <Button onClick={() => switchNetwork?.(56)}>Switch Network</Button>
      </Container>
      )}
      </>
      )}
      {claimProcess && (
        <ModalOverlay>
        <ModalContent>
          <h3>Claiming...</h3>
          <Spinner />
          <p>Your transaction is being processed.</p>
        </ModalContent>
      </ModalOverlay>
      )}

{stakeProcess && (
        <ModalOverlay>
        <ModalContent>
          <h3>Staking...</h3>
          <Spinner />
          <p>Your transaction is being processed.</p>
        </ModalContent>
      </ModalOverlay>
      )}

{withdrawProcess && (
        <ModalOverlay>
        <ModalContent>
          <h3>Withdrawing...</h3>
          <Spinner />
          <p>Your transaction is being processed.</p>
        </ModalContent>
      </ModalOverlay>
      )}

    </Containers>
  );
};

export default StakingDashboard;
