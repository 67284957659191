import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface TimerProps {
  timeLeft: number; // Time in seconds
  title: string;
}

const TimerWrapper = styled.div`
  display: flex;
  gap: 10px;
  font-family: Arial, sans-serif;
  font-size: 15px;
  color: #fff; /* White text color for dark theme */
`;

const Title = styled.h5`
  display: flex;
  gap: 10px;
  font-family: var(--font-normal);
  font-size: 13px;
  color: #fff; /* White text color for dark theme */
`;

const TimeBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border: 1px solid #444; /* Darker border color */
  border-radius: 5px;
  background-color: #333; /* Darker background */
  min-width: 50px;
  max-width: 50px;
  width: 50px;

`;

const Label = styled.div`
  font-size: 0.5rem;
  color: #bbb; /* Lighter text color for labels */
  margin-top: 5px;
`;


const Timer: React.FC<TimerProps> = ({ timeLeft,title }) => {
  const [time, setTime] = useState(timeLeft);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds: number) => {
    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return { days, hours, minutes, secs };
  };

  const { days, hours, minutes, secs } = formatTime(time);

  return (
    <div style={{display:'flex',flexDirection:'column',gap:10,marginTop:10}}>
    <Title>{title}</Title>
    <TimerWrapper>
      <TimeBox>
        <div>{days}</div>
        <Label>Days</Label>
      </TimeBox>
      <TimeBox>
        <div>{hours}</div>
        <Label>Hours</Label>
      </TimeBox>
      <TimeBox>
        <div>{minutes}</div>
        <Label>Minutes</Label>
      </TimeBox>
      <TimeBox>
        <div>{secs}</div>
        <Label>Seconds</Label>
      </TimeBox>
    </TimerWrapper>
    </div>
  );
};

export default Timer;
