import React from 'react';
import styled from 'styled-components';
import { FaPlay } from 'react-icons/fa';
import p5 from "../../assets/images/baaz-1-thumbnail.jpg";
import p2 from "../../assets/images/p2.png";
import p3 from "../../assets/images/p3.png";
import p4 from "../../assets/images/p4.png";
import p1 from "../../assets/images/baaz-2-thumbnail.jpg";

interface Post {
  thumbnail: string;
  link: string;
}

const posts: Post[] = [
  { thumbnail: p5, link: 'https://youtu.be/QpVGtGXOogc?si=RdK1XiumhaTXpRn9' },
  { thumbnail: p1, link: 'https://youtu.be/jRh8n1wHPzo?si=ue4sZi0QeiIK_tkY' },

];

const ShowcaseContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  perspective: 1000px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
}
`;

const Card = styled.div`
  position: relative;
  width: 100%;
  background-color: #000;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.5s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }


`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(1);
  transition: filter 0.3s ease;

  ${Card}:hover & {
    filter: brightness(1.2);
  }
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align "View Post" button at the bottom */
  align-items: center;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  pointer-events: none; /* Prevent Overlay from blocking hover */
`;

const PlayIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the wrapper */
  border: 3px solid white; /* Circle border */
  border-radius: 50%; /* Makes it circular */
  width: 80px; /* Fixed size for the circle */
  height: 80px; /* Matches width for a perfect circle */
  display: flex;
  justify-content: center;
  align-items: center; /* Centers the icon inside the circle */
  background-color: rgba(0, 0, 0, 0.2); /* Optional background for contrast */
`;

const PlayIcon = styled(FaPlay)`
  font-size: 40px; /* Icon size */
  color: white; /* Icon color */
  pointer-events: none;
`;
const CardP = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40%;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  cursor: pointer;
  opacity: 0; /* Hidden initially */
  transform: translateY(100%); /* Start from bottom */
  transition: transform 0.5s ease, opacity 0.5s ease;

  ${Card}:hover & {
    transform: translateY(0); /* Fully visible */
    opacity: 1; /* Show container */
    pointer-events: auto; /* Allow interaction on hover */
  }
`;

const ViewPost = styled.a`
  text-decoration: none;
  padding: 5px 15px;
  background-color: #ffc107;
  color: black;
  font-family:var(--font-medium);
  border-radius: 5000px;
  font-size: 16px;
  transform: translateY(100%); /* Start position to match CardP */
  opacity: 1; /* Always visible when hovered */
  pointer-events: auto; /* Allow interaction */

  ${Card}:hover & {
    transform: translateY(0); /* Adjusts position without animation */
    opacity: 1; /* Ensure visibility */
    pointer-events: auto;
  }
  
  @media (max-width: 768px) {
    font-size: 12px;
}

`;


const InstagramShowcase: React.FC = () => {
  return (
    <ShowcaseContainer>
      {posts.map((post, index) => (
        <Card key={index}>
          <Thumbnail src={post.thumbnail} alt={`Post ${index + 1}`} />
          <Overlay>
          <PlayIconWrapper>
              <PlayIcon />
            </PlayIconWrapper>   
            <CardP>
                <ViewPost href={post.link} target="_blank" rel="noopener noreferrer">
              View Post
            </ViewPost>
            </CardP>     
          </Overlay>
        </Card>
      ))}
    </ShowcaseContainer>
  );
};

export default InstagramShowcase;
