import React, { useEffect, useState } from "react";
import "./Hero.scss";

import styled,{ keyframes } from "styled-components";
import Progress from "./Progress"
import { calculateTokens, buyTokens,fetchPresaleDetails } from "../../utils/Connectors/Presale";




const Main = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 50% 50%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
  .shield {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .poko-shield {
      padding: 40px;
      
      background-color: rgba(38, 38, 38, 0.4); /* #262626 with 10% opacity */
      border-radius: 20px;
      backdrop-filter: blur(1px); /* Apply blur effect */

      border: 1px solid #606060;
      @media (max-width: 768px) {
        padding: 20px;
        width:100%;

      }
    }

    .timer {
      display: flex;
      justify-content: center;
      gap: 10px;
    }

    .time-block {
      background-color: rgba(38, 38, 38, 0.6); /* #262626 with 10% opacity */
      border-radius: 8px;
      padding: 10px;
      text-align: center;
      width: 80px;
      margin-bottom: 10px;

      @media (max-width: 768px) {
        width: 40px;

      }
    }

  

    .time-block span:first-child {
      font-size: 2rem;
      font-weight: bold;
      display: block;

      @media (max-width: 768px) {
        font-size: 1rem;

      }
    }

    @media (max-width: 600px) {
     

      .time-block {
        margin-bottom: 10px;
      }
    }

    h1 {
      text-align: center;
      margin-bottom: 30px;

      @media (max-width: 768px) {
        font-size: 20px;

      }
    }

    img {
      width: 100%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .texture {
    height: 100%;
    display: flex;
    padding-left: 70px;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      padding-left: 10px;
      padding-top: 100px;
    }

    h1 {
      font-size: 57px;
      font-weight: 800;
      font-family: var(--font-bold);
      color: #fff;
      @media (max-width: 768px) {
        font-size: 30px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 300;
      color: #a0a0a0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
`;

const Buttons = styled.div`
  width: 80%;
  display: flex;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  font-size: 55px;

  a {
    width: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
    background-color: transparent;
    color: #ffba00;
    font-size: 18px;

    border: 1px solid #ffba00;
    border-radius: 5px;
    text-align: center;
  }
  @media (max-width: 768px) {
    display: grid;

    grid-template-columns: 1fr;
    a {
      width: 100%;
      padding-top: 9px;
      padding-bottom: 9px;
      background-color: transparent;
      color: #ffba00;
      font-size: 18px;
      border: 1px solid #ffba00;
      border-radius: 5px;
      text-align: center;
    }
  }
`;

const Hero: React.FC = () => {
  const [bnbPrice, setBnbPrice] = useState(""); // Placeholder for BNB price
  const [raisedBNB, setRaisedBNB] = useState(""); // Placeholder for BNB price


  const [checked, setChecked] = React.useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const fetchDetails = async () => {

        try {
          const details = await fetchPresaleDetails('0x05Ec40277Bd8246D1A79D26e44BcB00fde8C7A38'); // Pass the correct address
          const totalPurchasedUSD = parseFloat(details.totalPurchasedUSD);

          // Perform the calculation
          const raisedBNB = totalPurchasedUSD * Number(bnbPrice);

          // Update the state, ensuring it's a string
          setRaisedBNB(raisedBNB.toString());
        } catch (error) {
          console.error('Error fetching presale details:', error);
        }

    };

    // Fetch immediately and then start interval
    fetchDetails();
    intervalId = setInterval(fetchDetails, 5000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [ bnbPrice]); // Re-run if address or bnbPrice changes
  
  // Simulate token calculation (replace with actual logic)

  async function fetchBscPairData(pairAddress:string) {
    const apiUrl = `https://openapi.dexview.com/latest/dex/pairs/bsc/${pairAddress}`;
    
    try {
      const response = await fetch(apiUrl);
      
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
      }
      
      const data = await response.json();
  
      // Extract relevant data from the response
      if (data && data.pair) {
        const { baseToken, quoteToken, priceNative, priceUsd, liquidity, txns, volume } = data.pair;
  
  
        console.log("Price (USD):", priceUsd);
        setBnbPrice(priceUsd)
  

      } else {
        console.error("Pair data is missing in the response.");
      }
    } catch (error:any) {
      console.error("Error fetching pair data:", error.message);
    }
  }
  
  // Usage: Pass the pair address as an argument
  fetchBscPairData("0x36696169c63e42cd08ce11f5deebbcebae652050");

  
  
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date("2025-02-01T18:40:00Z").getTime();

    const updateTimer = () => {
      const now = new Date();
      const timeDifference = targetDate - now.getTime();

      if (timeDifference <= 0) {
        setTimeLeft({ days: 0, hours:0 , minutes: 0, seconds: 0 });
        clearInterval(intervalId);
        return;
      }

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    };

    const intervalId = setInterval(updateTimer, 1000);
    updateTimer(); // Initial call to set time immediately

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="hero-wrapper">
        <div className="box-wrapper">
          {" "}
          {/* Added 'waves-background' class */}
          <Main>
            <div className="texture">
              <div>
                <h1>
                  Secure and Seamless Crypto Payments with{" "}
                  <span style={{ color: "#ffba00" }}>Baaz Coin</span>
                </h1>
                <p>
                  Revolutionize Your Transactions – Baaz Coin brings you a secure,
                  transparent, and effortless way to manage crypto payments.
                </p>
                <Buttons>
                  <button   onClick={() => window.location.href = "./#buy"}
 className={`button-prim `}>
                    <div
                      style={{
                        display: "flex",
                        fontSize: 16,
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      Presale is live now
                    </div>
                  </button>
                  <button   onClick={() => window.location.href = "https://docs.baazcoin.com"}
 className={`button-prim `}>
                    <div
                      style={{
                        display: "flex",
                        fontSize: 16,
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      Read Documentation
                    </div>
                  </button>
                </Buttons>
              </div>
            </div>
            <div className="shield">
              <div className="poko-shield">
                <h1>Baaz Coin Sale Live</h1>


                <div className="timer">
                  <div style={{ textAlign: "center" }}>
                    <div className="time-block">
                      <span>{timeLeft.days}</span>
                    </div>
                    Day
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <div className="time-block">
                      <span>{timeLeft.hours}</span>
                    </div>
                    Hours
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <div className="time-block">
                      <span>{timeLeft.minutes}</span>
                    </div>
                    Min
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <div className="time-block">
                      <span>{timeLeft.seconds}</span>
                    </div>
                    Sec
                  </div>
                </div>

                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:20}}>
                  <h3 style={{fontSize:14,marginLeft:5,marginBottom:3}}>Softcap : <span style={{color:'#ffba00'}}>$440K</span></h3>
                  <h3 style={{fontSize:14,marginRight:5,marginBottom:3,textAlign:'right'}}>Hardcap : <span style={{color:'#ffba00'}}>$4.5M</span></h3>

</div>
<Progress progress={51.1+Number(raisedBNB)*100/4500000} />

                <h3 style={{fontSize:17,marginRight:5,marginBottom:3,textAlign:'center',marginTop:20}}>0.07$ : <span style={{color:'#ffba00'}}>1 BAAZ</span></h3>

<div style={{display:'flex',justifyContent:'center',alignItems:'center',margin:20,marginTop:10}}>
                <button   onClick={() => window.location.href = "./#buy"}
 className={`button-prim `}>
                    <div
                      style={{
                        display: "flex",
                        fontSize: 16,
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      Buy Now
                    </div>
                  </button>
                  </div>
              </div>
            </div>



          </Main>
        </div>
    
    </div>
  );
};

export default Hero;
