import { MaxUint256, ethers, formatEther, parseEther } from "ethers";
import staking_abi from "./abis/staking.json";
import token_abi from "./abis/token_abi.json";

import { STAKING_ADDRESS,TOKEN_ADDRESS } from "./Address";

const getSigner = async (address: string) => {
  const windowEth = window as any;
  const provider = new ethers.BrowserProvider(windowEth.ethereum);
  return await provider.getSigner(address);
};

// Exported functions

export const getAPY = async () => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, provider);
  const apy = await contract.apy();
  return apy;
};

export const getLockPeriod = async () => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, provider);
  const lockPeriod = await contract.lockPeriod();
  return lockPeriod.toString();
};

export const getRewardCycle = async () => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, provider);
  const rewardCycle = await contract.rewardCycle();
  return rewardCycle.toString();
};

export const stakeTokens = async (address: string, amount: string) => {
  const signer = await getSigner(address);

  // Create contract instances
  const stakingContract = new ethers.Contract(STAKING_ADDRESS, staking_abi, signer);
  const tokenContract = new ethers.Contract(TOKEN_ADDRESS, token_abi, signer);

 
    const approvalTx = await tokenContract.approve(STAKING_ADDRESS, parseEther(amount));
    await approvalTx.wait();


  // Now stake the tokens
  const tx = await stakingContract.stake(parseEther(amount));
  await tx.wait();

  return tx;
};


export const claimAllRewards = async (address: string) => {
  const signer = await getSigner(address);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, signer);
  const tx = await contract.claimAllRewards();
  await tx.wait();
  return tx;
};

export const claimReward = async (address: string, stakeId: number) => {
  const signer = await getSigner(address);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, signer);
  const tx = await contract.claimReward(stakeId);
  await tx.wait();
  return tx;
};

export const emergencyWithdraw = async (address: string) => {
  const signer = await getSigner(address);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, signer);
  const tx = await contract.emergencyWithdraw();
  await tx.wait();
  return tx;
};

export const withdrawStake = async (address: string, stakeId: number) => {
  const signer = await getSigner(address);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, signer);
  const tx = await contract.withdraw(stakeId);
  await tx.wait();
  return tx;
};

export const getTotalStaked = async () => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, provider);
  const totalStaked = await contract.totalStaked();
  return formatEther(totalStaked);
};

export const getTotalUser = async () => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, provider);
  const totalStaked = await contract.totalUsers();
  return totalStaked;
};


export const getTotalUserStaked = async (address: string) => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, provider);
  const totalUserStaked = await contract.totalUserStaked(address);
  return formatEther(totalUserStaked);
};

export const getStakeCount = async (address: string) => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, provider);
  const stakeCount = await contract.stakeCount(address);
  return stakeCount.toString();
};

export const getStakeDetails = async (address: string, stakeId: number) => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, provider);
  const stakeDetails = await contract.stakes(address, stakeId);
  return {
    amount: formatEther(stakeDetails.amount),
    startTime: stakeDetails.startTime.toString(),
    lastClaimTime: stakeDetails.lastClaimTime.toString(),

    withdrawn: stakeDetails.withdrawn,
  };
};

export const timeLeftForClaim = async (address: string, stakeId: number) => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, provider);
  const timeLeft = await contract.timeLeftForClaim(address, stakeId);
  return timeLeft.toString();
};

export const timeLeftForWithdrawal = async (address: string, stakeId: number) => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, provider);
  const timeLeft = await contract.timeLeftForWithdraw(address, stakeId);
  return timeLeft.toString();
};



export const fetchRewards = async (address: string) => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, provider);
  const timeLeft = await contract.fetchRewards(address);
  return formatEther(timeLeft.toString());
};



export const fetchRewardsByStakeId = async (address: string, stakeId: number) => {
  const provider = new ethers.BrowserProvider(window.ethereum);
  const contract = new ethers.Contract(STAKING_ADDRESS, staking_abi, provider);
  const timeLeft = await contract.fetchRewardsByStakeId(address, stakeId);
  return formatEther(timeLeft.toString());
};

